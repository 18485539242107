import fs from 'fs';
import path from 'path';

export class FileCache<T extends {[key: string]: unknown}> {
	private cacheDir: string;
	constructor(cacheDir: string) {
		this.cacheDir = cacheDir;
		fs.mkdirSync(this.cacheDir, {recursive: true});
	}

	getFilePath(key: string): string {
		const hash = Buffer.from(key).toString('base64');
		return path.join(this.cacheDir, hash);
	}

	set(key: string, value: T, ttlInSeconds: number = 60 * 5): void {
		const filePath = this.getFilePath(key);
		const data = {
			value: value,
			expiresAt: Date.now() + ttlInSeconds * 1000, // Calculate the expiration timestamp
		};

		fs.writeFileSync(filePath, JSON.stringify(data));
	}

	get(key: string): T | null {
		const filePath = this.getFilePath(key);

		try {
			const data = fs.readFileSync(filePath, 'utf8');
			const parsedData = JSON.parse(data);

			if (parsedData.expiresAt > Date.now()) {
				// Data is still fresh, return the value
				return parsedData.value;
			} else {
				// Data has expired, remove the file and return null
				fs.unlinkSync(filePath);
				return null;
			}
		} catch (error) {
			// File not found or unable to parse JSON
			return null;
		}
	}

	delete(key: string): void {
		const filePath = this.getFilePath(key);

		try {
			fs.unlinkSync(filePath);
		} catch (error) {
			// File not found
		}
	}
}
