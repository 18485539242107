import {useOpenConsentScreen} from '@app/components/CookieBanner';
import {useCallback} from 'react';
import {useTracking} from '@app/modules/tracking';
import {config} from '@modules/config';

export const isStandaloneLandingUrl = (href?: string): boolean => {
	if (href?.startsWith('https://')) {
		return new URL(href).pathname.startsWith(config.get('basePath'));
	} else if (href?.startsWith(config.get('basePath'))) {
		return true;
	}
	return false;
};

export const useFooterLinkConfig = () => {
	const {trackEvent} = useTracking();
	const updateConsent = useOpenConsentScreen();

	const linkCallback = useCallback(
		(key: string | undefined) => () => {
			if (!key) return;
			if (key === 'cookie-preference') {
				updateConsent();
			}
			trackEvent({
				event: 'click',
				eventaction: `click-${key}-link`,
				eventcategory: 'footer',
				eventlabel: 'footer-social-link',
			});
		},
		[trackEvent, updateConsent],
	);
	return {linkCallback};
};
