import type {FunctionComponent} from 'react';
import {useRouter} from 'next/router';
import {useTracking} from '@app/modules/tracking';

// Components
import {
	LandingFooter,
	LandingFooterBottom,
	LandingFooterSocialNetwork,
	LandingFooterSocialNetworkIcon,
} from '@tempo/landing';
import {
	AppleStoreBadge,
	DeezerLogo,
	Link,
	PlayStoreBadge,
	Select,
	StoreBadgeGroup,
} from '@tempo/core';
import {
	FacebookIcon,
	InstagramMonochromeIcon,
	XIcon,
	YoutubeIcon,
} from '@tempo/icons';
import {Navigation} from './navigation';
import {useLocation} from '@app/hooks/use-location';
import {useLocaleChange} from '@app/hooks/use-location-change';
import type {AvailableLanguage} from '@app/types/api/deezer/get-unlogged-data';
import {getLocaleFromLegacyLocale} from '@deezer/deezer-compatibility';
import {useUnloggedData} from '@app/modules/ssg-data/unlogged-data';

const SOCIAL_ICONS = {
	facebook: <FacebookIcon />,
	instagram: <InstagramMonochromeIcon />,
	youtube: <YoutubeIcon />,
	twitter: <XIcon />,
};

const Footer: FunctionComponent<{openedLocales: string[]}> = ({
	openedLocales,
}) => {
	const {locale} = useRouter();
	const [handleChangeLocale] = useLocaleChange();
	const [{availableLanguages, socialLinks, footerSections: groups}] =
		useUnloggedData();
	const {trackEvent} = useTracking();

	const filteredAvailableLanguages = (() => {
		const filtered: AvailableLanguage[] = [];
		availableLanguages?.forEach((lang) => {
			const locale = getLocaleFromLegacyLocale(lang.value);
			if (openedLocales.includes(locale)) {
				filtered.push({...lang, value: locale});
			}
		});
		return filtered;
	})();

	const handleStoreTracking = (label: string) => {
		trackEvent({
			event: 'click',
			eventaction: 'download-app',
			eventcategory: 'footer',
			eventlabel: `${label}-logo`,
		});
	};

	const {getDeezerUrl} = useLocation();

	return (
		<LandingFooter data-cy="footer">
			<StoreBadgeGroup>
				<AppleStoreBadge
					data-cy="app-store"
					target="_blank"
					onClick={() => handleStoreTracking('apple')}
					locale={locale}
				/>
				<PlayStoreBadge
					data-cy="google-play-store"
					target="_blank"
					onClick={() => handleStoreTracking('google')}
					locale={locale}
				/>
			</StoreBadgeGroup>

			<Navigation groups={groups} />

			<LandingFooterBottom
				logo={
					<Link
						href={getDeezerUrl()}
						data-cy="deezer-logo"
						aria-label="deezer site"
					>
						<DeezerLogo />
					</Link>
				}
				socialIcons={
					<LandingFooterSocialNetwork>
						{socialLinks &&
							socialLinks.map((link) => (
								<LandingFooterSocialNetworkIcon
									key={link.name}
									aria-label={link.name}
									href={link.url}
									icon={SOCIAL_ICONS[link.name]}
								/>
							))}
					</LandingFooterSocialNetwork>
				}
				languageSelector={
					filteredAvailableLanguages.length > 1 && (
						<Select
							variant="filled"
							value={locale}
							onChange={handleChangeLocale}
						>
							{filteredAvailableLanguages.map((language) => (
								<option key={language.label} value={language.value}>
									{language.label}
								</option>
							))}
						</Select>
					)
				}
			/>
		</LandingFooter>
	);
};

export default Footer;
