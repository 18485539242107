import {getISOLocaleFromLocale} from '@deezer/deezer-compatibility';
import {usePathname} from 'next/navigation';
import type {ChangeEventHandler} from 'react';
import {useCallback} from 'react';
import {config} from '@modules/config';

export const useLocaleChange = (): readonly [
	ChangeEventHandler<HTMLSelectElement>,
] => {
	const pathname = usePathname();
	const handleLocaleChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
		(event) => {
			if (typeof window !== 'undefined' && window.consentScreenApi) {
				window.consentScreenApi.update({
					lang: getISOLocaleFromLocale(event.target.value),
				});
			}
			window.location.assign(
				`${config.get('basePath')}/${event.target.value}${pathname}`,
			);
		},
		[pathname],
	);
	return [handleLocaleChange];
};
