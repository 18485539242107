import React, {useCallback} from 'react';

// Modules
import type {FunctionComponent} from 'react';
import {useRouter} from 'next/router';

// Config
import {config} from '@modules/config';
import {useUser} from '@deezer/react-user';
import type {ConsentScreenApi} from '@tempo/cookie';
import {CookieBanner} from '@tempo/cookie';
import {getISOLocaleFromLocale} from '@deezer/deezer-compatibility';

const UA_BOT_PATTERN = config.get('botCrawler');
const uaBotRegex = new RegExp(UA_BOT_PATTERN, 'i');

// Window type upersede
declare global {
	interface Window {
		// TODO: fix my types in tempo to remove the unknown
		consentScreenApi?: Omit<ConsentScreenApi, 'update'> & {
			update: (args: unknown) => void;
		};
		onDzrGDPRReady?: (initApi: () => Promise<ConsentScreenApi>) => unknown;
	}
}

export const useOpenConsentScreen = () => {
	return useCallback(() => {
		if (typeof window === 'undefined') return;
		window.consentScreenApi?.openPreferences(2, 'purposes');
	}, []);
};

export const ConsentScreen: FunctionComponent = () => {
	const {locale} = useRouter();
	const [user] = useUser();

	if (!user || uaBotRegex.test(navigator.userAgent)) {
		return null;
	}

	return (
		<CookieBanner
			lang={getISOLocaleFromLocale(locale)}
			userID={user.id}
			cookieDomain={config.get('cookieDomain')}
			gatewayHost={config.get('deezerWebsiteUrl')}
			scriptUrl={`${config.get('commonAssetsUrl')}/js/gdpr-appliance.js`}
		/>
	);
};
