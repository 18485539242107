import type {FunctionComponent} from 'react';
import React from 'react';
import Link from 'next/link';
import {useFooterLinkConfig} from './footer-link-handler';

// Tempo
import {
	LandingFooterLink,
	LandingFooterLinkGroup,
	LandingFooterLinks,
	LandingFooterCategoryLink,
} from '@tempo/landing';

// Type
import type {FooterSection} from '@app/types/api/deezer/get-unlogged-data';

export const Navigation: FunctionComponent<{
	groups: FooterSection[] | undefined;
}> = ({groups}) => {
	const {linkCallback} = useFooterLinkConfig();

	return (
		<LandingFooterLinks data-cy="footer-links">
			{groups?.map((group) => (
				<LandingFooterLinkGroup
					key={group.title}
					label={
						!group.to ? (
							group.title
						) : (
							<LandingFooterCategoryLink
								as={Link}
								href={group.to}
								onClick={linkCallback(group.key)}
							>
								{group.title}
							</LandingFooterCategoryLink>
						)
					}
				>
					{group.entries.map((link) => {
						const {to, key, label} = link;
						if (!to) {
							return (
								<LandingFooterLink
									as="button"
									key={key}
									onClick={linkCallback(key)}
								>
									{label}
								</LandingFooterLink>
							);
						}
						return (
							<LandingFooterLink
								href={to}
								key={key}
								as={Link}
								onClick={linkCallback(key)}
							>
								{label}
							</LandingFooterLink>
						);
					})}
				</LandingFooterLinkGroup>
			))}
		</LandingFooterLinks>
	);
};
