import {LegacyApi} from '@deezer/react-legacy-api';
import {config} from '@modules/config';
import {FileCache} from './file-cache';

export const statelessApi = new LegacyApi(config.get('serverLegacyApiHost'), {
	mode: 'cors',
	credentials: 'omit',
});
let fileCache: FileCache<{[key: string]: unknown}> | undefined;
const getFileCache = () => {
	if (!fileCache) {
		fileCache = new FileCache('.cache-ssg');
	}
	return fileCache;
};

/**
 * Only use it while making build-api calls
 */
export const backendApiCall: typeof statelessApi.call = async (params) => {
	if (typeof window !== 'undefined') {
		throw new Error(
			'use the apiCall via the useApiCall when requesting on the client',
		);
	}
	const vary = `${params.method}-${params.httpHeaders?.['Accept-Language']}`;

	const cachedValue = getFileCache().get(vary);
	if (cachedValue) return cachedValue;
	else {
		const res = await statelessApi.call(params);
		getFileCache().set(vary, res as {[key: string]: unknown});
		return res;
	}
};
