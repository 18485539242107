import {getCountryFromLocale} from '@app/utils/i18n';
import {useRouter} from 'next/router';
import type {GetUnloggedDataResponse} from '@app/types/api/deezer/get-unlogged-data';
import useSWR from 'swr';
import {backendApiCall, statelessApi} from './backend-api';
import {getNormalisedHref} from '@app/modules/location';

/**
 * Since GEOIP is only {fetch}ed client-side, putting it in the cache key will force a new {fetch} on the clien-side.
 * This will make sure we have the correct legal links from the legacy backend depending on the country we're in.
 */
const buildQueryKey = (locale: string, geoip: string | null = null) =>
	`deezer.getUnloggedData-${locale}-${geoip}`;

const getUnloggedDataOptions = (locale: string) => ({
	method: 'deezer.getUnloggedData',
	httpHeaders: {
		'Accept-Language': locale,
		Cookie: `dz_lang=${locale}`,
	},
});

/** SSG Pre{fetch} method. Does the first {fetch} with the provided queryClient */
export const getFooterLinksPerLang = async ({
	locale,
	locales,
}: {
	locale: string;
	locales: string[];
}): Promise<{[key: string]: GetUnloggedDataResponse}> => {
	const legacyLocale = getCountryFromLocale(locale, locales);
	const result = formatData(
		(await backendApiCall(
			getUnloggedDataOptions(legacyLocale),
		)) as GetUnloggedDataResponse,
	);
	return {
		[buildQueryKey(legacyLocale)]: result,
	};
};

export const useUnloggedData = (): readonly [
	Partial<GetUnloggedDataResponse>,
] => {
	const {locale, locales} = useRouter();
	const legacyLocale = getCountryFromLocale(locale, locales);

	const {data} = useSWR(
		buildQueryKey(legacyLocale),
		() => {
			/**
			 * Unfortunately, since we cannot update the credentials on a per call basis,
			 * we have to use another instance of legacy api
			 */
			return (
				statelessApi.call(
					getUnloggedDataOptions(locale),
				) as Promise<GetUnloggedDataResponse>
			).then(formatData);
		},
		{revalidateOnMount: true, revalidateOnFocus: false}, // useful to reload gatekeeped links with client GEOIP
	);
	return [data ?? {}];
};

const formatData = ({
	footerSections,
	...data
}: GetUnloggedDataResponse): GetUnloggedDataResponse => {
	const newFooterSections: GetUnloggedDataResponse['footerSections'] =
		footerSections.map((section) => {
			return {
				...section,
				...(section.to && {to: getNormalisedHref(section.to)}),
				entries: section.entries.map((entry) => {
					return {
						...entry,
						...(entry.to && {to: getNormalisedHref(entry.to)}),
					};
				}),
			};
		});
	const toReturn = {footerSections: newFooterSections, ...data};
	return toReturn;
};
